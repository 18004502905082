import React, {useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import {
  CssBaseline,
  Grid,
  useMediaQuery
} from "@mui/material";
import { CheckStatusForm } from "./Forms/CheckStatusForm";
import { CardForm } from "./Forms/CardForm";
import { makeStyles } from "@mui/styles";
import { SingleForm } from "./Forms/SingleForm";
import { GroupForm } from "./Forms/GroupForm";
import { PaymentStatus } from "./Forms/PaymentStatus";
import { CardAuthorisation } from "./Forms/CardAuthorisation";
import {LeftView} from "./Components/LeftView";
import {RightView} from "./Components/RightView";
import {PostixPaper} from "../Styles";
import {useTheme} from "@material-ui/core";
import {PaymentManual} from "./Forms/PaymentManual";
import {MainPaper} from "./Components/MainPaper";
import {Elements} from "@stripe/react-stripe-js";
import axios from "axios";
import {handleError} from "../support";
import {loadStripe} from "@stripe/stripe-js";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    zIndex: 10,
    padding: 0
  },
  leftImage: {
    // backgroundImage: "url(https://source.unsplash.com/h5FgjZ0oxNM/1600x900)",
    backgroundImage: "url('/images/banner.jpg')",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2),
  },
  rightImageMinimize: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: "url('/images/elephant.jpg')",
  },
  logo: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  logoImage: {
    height: "20vh",
    marginTop: "80vh",
  }
}));

export const stripePromise = loadStripe("pk_test_51JZKTSKsVIy23TkupCbhJrL67MqHRIBUfKqvkTih7wz8bNEvuNmf9B3Rx4nH2wbe8Db8A5ZimuI4ifsNtELCYUDp00xSamRAqz");
export const stripeDevPromise = loadStripe("pk_live_51JZKTSKsVIy23Tku8lecOXz3sQaORwnJ3qymhOsZL4MPSEZTieZ0oDXcn0KN56Je6KIw3b8Yuz84jHse4DL35QzJ001VQ5BjJS");

export const FormView: React.VFC = () => {
  const classes = useStyles();
  const location = useLocation();
  const currentPath = location.pathname.split("/")[2];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const largeMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);

  const [clientSecret, setClientSecret] = useState("");

  const [testMode, setTestMode] = useState(false);

  const appearance = {
    theme: 'stripe' as "flat" | "stripe" | "night"
  };



  // Enable the skeleton loader UI for optimal loading.
  const loader = 'auto';

  useEffect(() => {
    if (currentPath === "payment-card") {
      setLoading(true);
      axios.post("https://ywdjj0mi04.execute-api.eu-west-2.amazonaws.com/Prod/payment-intent", {
        "sessionID": localStorage.getItem("sessionId")
      }).then((res) => {
        setClientSecret(res.data.data.clientSecret);
        setLoading(false);
      }).catch((error) => {
        handleError(error, true);
        setLoading(false);
      })
    }

    const url = new URL(window.location.href);
    const eventID = url.pathname.split("/")[1]

    const allEventData = JSON.parse(localStorage.getItem("eventData"));
    const eventData = allEventData[eventID];

    setTestMode(Object.keys(eventData).includes("test") ? eventData["test"] as boolean : false);

    stripePromise.then((v) => {
      if (clientSecret) {
        const options = { mode: 'billing' };
        const elements = v.elements({ clientSecret, appearance });
        // @ts-ignore
        const addressElement = elements.create('address', options);
        addressElement.mount('#address-element');
      }
    })
  }, [location]);

  function getComponent(loading, setLoading) {

    console.log(location.pathname)
    switch (currentPath) {
      case "single-ticket":
        return <SingleForm loading={loading} setLoading={setLoading}/>;
      case "group-tickets":
        return <GroupForm />;
      case "check-status":
        return <CheckStatusForm loading={loading} setLoading={setLoading}/>;
      case "payment-card":
        return <CardForm loading={loading} setLoading={setLoading}/>;
      case "card-authorisation":
        return <CardAuthorisation loading={loading} setLoading={setLoading}/>;
      case "payment-status":
        return <PaymentStatus loading={loading} setLoading={setLoading}/>;
      case "payment-manual":
        return <PaymentManual loading={loading} setLoading={setLoading}/>;
    }
  }

  return (
        <Grid container component="main" className={classes.root}>
          <CssBaseline />
          <LeftView openLoader={loading}>
              <MainPaper isMobile={isMobile} largeMobile={largeMobile}>
                {(clientSecret) && (<Elements options={{clientSecret, appearance, loader}} stripe={testMode? stripePromise : stripeDevPromise}>
                  {getComponent(loading, setLoading)}
                </Elements>)}
                {(!clientSecret && (currentPath === "single-ticket" || currentPath === "payment-status" || currentPath === "check-status")) && (<>{getComponent(loading, setLoading)}</>)}
              </MainPaper>
          </LeftView>
          <RightView/>
        </Grid>
  );
};
