import {styled} from "@mui/material";
import {Grid, Button, Typography, Box} from "@material-ui/core";
import GothamBold from "./fonts/Gotham-Bold.woff2";

//const theme = useTheme();

export const Root = styled(Grid)(({}) => ({
    position: "fixed",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    padding: 0
}));

export const ContainerWithImage = styled(Grid) <{image?: String}>
(({image: image = "/images/elephant.jpg"}) => ({
    backgroundImage: `url('${image}')`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    position: "relative",
}))

export const PostixPaper = styled("div") <{isMobile?: true | false, largeMobile?: true | false, background?: string}>
(({isMobile = false, largeMobile = true, background = `linear-gradient(160deg, ${"rgba(237,225,207,0.8)"} 0%, ${"rgba(237,225,207,0.8)"} 35%, ${"rgba(163,183,170,0.6)"} 100%) !important`}) => ({
    background: background,
    backdropFilter: "blur(10px)",
    margin: isMobile? 0 : 4,
    marginRight: largeMobile? "initial" : 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: isMobile? 12 : 8,
    borderRadius: 17,
    position: "absolute",
    bottom: 8,
    right: isMobile? 0 : 4,
    width: isMobile? (largeMobile? "95vw" : "50vw") : "30vw",
    left: isMobile? (largeMobile? "2.5vw" : "initial") : "initial",
    maxHeight: isMobile? "96vh": "90vh",
    maxWidth: "96vw",
    overflowY: "auto",
    overflowX: "hidden",
    paddingLeft: isMobile? "20px": "3%",
    paddingRight: isMobile? "20px": "3%",
    paddingTop: isMobile? "20px": "3%",
    paddingBottom: isMobile? "20px": "3%",
}))

export const NormalButtonStyle = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'thin'
}) <{thin?: boolean}>
(({thin = false}) => ({
    marginTop: "1px",
    marginBottom: "8px !important",
    borderRadius: "40px !important",
    padding: thin? "6px !important" : "12px !important",
    backgroundColor: "white !important",
    height: thin? "50px" : "70px"
}))

export enum Colours {
    SamaggiOrange = "#d7a02a",
    TranslucentBlack = "rgba(0, 0, 0, 0.2)",
    None = "#000000",
    LightGray = "#BBBBBB",
    White = "rgba(255, 255, 255, 1.0)"
}

interface textProps {
    colour?: Colours;
    strokeColour?: Colours;
    strokeWidth?: number;

    fontWeight?: number;

    fontSize?: string;
}

export const SamaggiText = styled(Typography) <textProps>
(({colour = Colours.SamaggiOrange, strokeColour = Colours.SamaggiOrange, strokeWidth = 0, fontWeight = 400, fontSize = "4.5em"}) => ({
    fontSize: fontSize,
    color: colour,
    lineHeight: "1",
    WebkitTextStrokeColor: strokeColour,
    WebkitTextStrokeWidth: `${strokeWidth}px`,
    fontFamily: GothamBold,
    fontWeight: String(fontWeight)
}))

export const LogoContainer = styled(Box)
(({}) => ({
    display: "flex",
    justifyContent: "center"
}))

export const Logo = styled("img")
(({}) => ({
    height: "25vh",
    marginTop: "75vh",
}))

export const ButtonText = styled(Typography)
(({}) => ({
    color: "#293c7e",
    textAlign: "center",
    width: "100% !important"
}))

export const ButtonTextContainer = styled(Box)
(({}) => ({
    width: "100% !important"
}))

export const ButtonContainer = styled("div")
(({}) => ({
    width: "100%"
}))

export const SamaggiBox = styled(Box)
(({}) => ({
    display: "flex",
    flexDirection: "row"
}))
