import React, {useEffect, useState} from "react";
import {Field, Form, Formik} from "formik";
import {useHistory, useLocation} from "react-router-dom";
import {Backdrop, Box, Button, CircularProgress, Divider, Grid, makeStyles, Typography} from "@material-ui/core";
import axios from "axios";
import {MenuHeader} from "../Components/MenuHeader";
import {getTicketData} from "../Global";
import {BackButton} from "../Components/BackButton";
import {SamaggiField} from "../Components/SamaggiField";
import {Link} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    submit: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        borderRadius: 40,
        padding: theme.spacing(1.5),
        backgroundColor: "white",
        "&:disabled": {
            backgroundColor: "rgba(57, 57, 57, 0.14)",
        },
    },
    cssLabel: {
        color: "white"
    },
    boxRoot: {
        border: "1px solid lightgray",
        color: "white",
        input: {
            color: "white",
        }
    },
    multilineColor:{
        color:'red'
    },
    cssFocused: {
        color: "white",
        border: "0px solid lightgray",
        input: {
            color: "white",
        }
    },
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        minHeight: "100vh"
    },
}));

export const SingleForm: React.VFC<{ loading, setLoading}> = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    let setOpenLoader = props.setLoading
    const params = new URLSearchParams(history.location.search);

    const [data, setData] = useState(undefined);
    const [tcLink, setTCLink] = useState("");
    const [textColour, setTextColour] = useState("#293c7e");
    const [tertiaryColour, setTertiaryColour] = useState("#4a5579");

    useEffect(() => {
        const url = new URL(window.location.href);
        const eventID = url.pathname.split("/")[1]

        if (eventID !== "") {
            const allEventData = JSON.parse(localStorage.getItem("eventData"));
            const eventData = allEventData[eventID];

            if (eventData !== undefined) {
                setTCLink(eventData["web-terms-and-conditions"])
                setTextColour(eventData["web-text-primary-colour"])
                setTertiaryColour(eventData["web-text-tertiary-colour"])
            }
        }
    }, [location]);


    async function onConfirm(values) {
        console.log(values);
        setOpenLoader(true);

        let ticketHolders = [];
        for (let i = 0; i < parseInt(localStorage.getItem("singleQuantity")); i++) {
            ticketHolders.push(
                "{firstName: " +
                '"' +
                values.firstname +
                '"' +
                ", lastName: " +
                '"' +
                values.lastname +
                '"' +
                "}"
            );
        }

        console.log(ticketHolders.join().replaceAll("'", ""));
        axios({
            url: process.env.REACT_APP_API_URL,
            method: "post",
            data: {
                query: `
        query On_details_confirmation {
          on_details_confirmation (email: "${
                    values.email
                }", sessionId: "${localStorage.getItem(
                    "sessionId"
                )}", ticketHolders: [${ticketHolders.join().replaceAll("'", "")}]){
            details
            status
            error {
              message
            }
          }
         }
        `,
            },
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
            },
        })
            .then((result) => {
                console.log(result.data.data.on_details_confirmation);
                if (
                    result.data.data.on_details_confirmation.status &&
                    result.data.data.on_details_confirmation.status === 200
                ) {
                    console.log("DATA!")
                    console.log(data)
                    localStorage.setItem("totalPrice", data.price);
                    localStorage.setItem("currency", data.currency);
                    setOpenLoader(false);
                    history.push({pathname: "./payment-card", search: history.location.search});
                } else {
                    alert(result.data.data.on_details_confirmation.error.message);
                    setOpenLoader(false);
                    history.push({pathname: "./", search: history.location.search});
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function onSelectTicket() {
        setOpenLoader(true);
        console.log(localStorage.getItem("selectedTicketType"));

        const referrer = params.get("referrer") == null ? "" : params.get("referrer");


        axios({
            url: process.env.REACT_APP_API_URL,
            method: "post",
            data: {
                query: `
        query on_quantity {
          on_quantity(qty: ${parseInt(
                    localStorage.getItem("singleQuantity")
                )}, type: "${localStorage.getItem("selectedTicketType")}", referrer: "${referrer}") {
            details
            price
            sessionId
            currency
            startingDatetime
            status
          }
         }
        `,
            },
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
            },
        })
            .then((result) => {
                console.log(result.data.data)
                console.log("ON QUANTITY SUCCESS");
                if (result.data.data.on_quantity.status !== 200) {
                    alert(result.data.data.on_quantity.details);
                } else {
                    setData(result.data.data.on_quantity);
                    setOpenLoader(false);
                    localStorage.setItem("sessionId", result.data.data.on_quantity.sessionId);
                }
            })
            .catch(function (error) {
                console.log("ON QUANTITY FAILED");
                console.log(error);
                alert("There was an error adding your tickets to cart, please try again later. If the issue persists please contact us.")
                history.push({pathname: "./", search: history.location.search});
            });
    }

    useEffect(() => {
        console.log("ON QUANTITY CALL");
        onSelectTicket().then(() => {});
    }, []);

    if (data) {
        return (
            <div style={{width: "100%"}}>
                <Box display="flex" flexDirection="column" alignContent="space-between">
                    <BackButton onClick={() => history.push({pathname: "./", search: history.location.search})}/>
                    <Box display="flex" alignItems="flex-start" flexDirection="column">
                        <MenuHeader withEventName extraPad/>
                    </Box>
                    <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                        marginTop="5%"
                    >
                        <Box display="flex" flexDirection="row">
                            <Typography variant="h5" style={{ color: textColour, fontWeight: "bold" }}>
                                {getTicketData(localStorage.getItem("selectedTicketType")).name.toUpperCase()}
                            </Typography>
                        </Box>

                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Typography variant="subtitle1" style={{ color: textColour, textAlign: "center" }}>
                                Total Price for {localStorage.getItem("singleQuantity")}{" "}
                                Ticket{localStorage.getItem("singleQuantity") === "1" ? "" : "s"} : {data.currency}{data.price !== null ? data.price.toFixed(2) : 0}
                            </Typography>
                            <Divider
                                style={{
                                    width: "100%",
                                    backgroundColor: tertiaryColour,
                                    margin: "5%",
                                }}
                            />
                            <Typography variant="subtitle2" style={{ color: textColour }}>
                                Please complete the order within
                            </Typography>
                            <Typography variant="subtitle2" style={{ color: textColour }}>
                                15 minutes or the session will expire.
                            </Typography>
                        </Box>

                        <Formik
                            initialValues={{
                                firstname: "",
                                lastname: "",
                                email: "",
                                email2: "",
                                // email: "",
                                // emailReconfirm: "",
                                agree: false,
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                // onConfirm(values);
                                console.log(values)
                                if (values.email === values.email2) {
                                    onConfirm(values);
                                } else {
                                    alert("Ensure your email address matches!")
                                    setSubmitting(false);
                                }
                            }}
                        >
                            {(formik) => (
                                <Form>
                                    <Grid container direction="column" spacing={2} style={{marginTop: "10px"}}>
                                        <Grid item>
                                            <SamaggiField label={"First name"} name={"firstname"} placeholder={"Enter your first name"} type={"text"}/>
                                        </Grid>
                                        <Grid item>
                                            <SamaggiField label={"Last name"} name={"lastname"} placeholder={"Enter your last name"} type={"text"}/>
                                        </Grid>
                                        <Grid item>
                                            <SamaggiField label={"Email Address"} name={"email"} placeholder={"Enter your email address"} type={"email"}/>
                                        </Grid>
                                        <Grid item>
                                            <SamaggiField label={"Re-type Email Address"} name={"email2"} placeholder={"Enter your email address again"} type={"email"}/>
                                        </Grid>
                                        <Box display="flex" flexDirection="vertical" alignItems="center"
                                             justifyContent="center" my="10%">
                                            <Field required type="checkbox" name="agree"/>
                                            <Typography variant="subtitle2" style={{color: textColour, marginLeft: "4px"}}>
                                                Agree to the our&nbsp;
                                            </Typography>
                                            <Link variant="subtitle2" style={{color: textColour, paddingLeft: "3px"}} href={tcLink} target="_blank" rel="noopener noreferrer">terms & conditions.</Link>
                                        </Box>
                                        <Grid container direction={"row"} justifyContent="center" alignItems="center">
                                            <Grid item>
                                                <Button fullWidth type="submit" className={classes.submit}
                                                        disabled={formik.isSubmitting}>
                                                    <Typography variant="subtitle2" style={{color: textColour}}>
                                                        CARD PAYMENT
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </div>
        );
    } else {
        return (
            <Backdrop style={{ color: "#inherit", zIndex: 1 }} open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }
};
