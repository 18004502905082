import React, {useEffect, useState} from "react";
import { Formik, Form, Field } from "formik";
import { useHistory } from "react-router-dom";
import {
    Typography,
    Box,
    makeStyles,
    useMediaQuery,
    useTheme,
    Divider,
    Grid, MenuItem
} from "@material-ui/core";
import axios from "axios";
import { months, years } from "./Dropdown";
import {MenuHeader} from "../Components/MenuHeader";
import {TextField} from "formik-mui";
import {NormalButton} from "../Components/NormalButton";
import {SamaggiField} from "../Components/SamaggiField";
import {BackButton} from "../Components/BackButton";
import {Elements, PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {handleError} from "../../support";

const useStyles = makeStyles((theme) => ({
    submit: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        borderRadius: 40,
        padding: theme.spacing(1.5),
        backgroundColor: "white",
        "&:disabled": {
            backgroundColor: "rgba(57, 57, 57, 0.14)",
        },
    },
    paper: {
        background:
            "linear-gradient(45deg, rgba(215, 160, 42, 0.4) 10%, rgba(23, 69, 118, 0.5) 70%)",
        backdropFilter: "blur(10px)",
        display: "flex-wrap",
        flexWrap: "wrap",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(2, 5),
        borderRadius: 30,
        flexGrow: 1,
        overflow: "hidden",
        minHeight: "100%",
        height: "80vh",
    },
    paperMobile: {
        background:
            "linear-gradient(45deg, rgba(215, 160, 42, 0.4) 10%, rgba(23, 69, 118, 0.5) 70%)",
        backdropFilter: "blur(10px)",
        display: "flex-wrap",
        flexWrap: "wrap",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(3, 1),
        borderRadius: 30,
        flexGrow: 1,
        overflow: "hidden",
        minHeight: "100%",
        height: "80vh",
    },
    mobileDivider: {
        width: "80%",
        marginTop: "5%",
        marginBottom: "5%",
        backgroundColor: "white",
    },
    samaggiTextOrange: {
        fontSize: "4.5em",
        color: "#d7a02a",
        lineHeight: "1",
        fontFamily: "gotham-bold",
    },
    samaggiTextTransparent: {
        fontSize: "4.5em",
        WebkitTextStrokeColor: "#d7a02a",
        WebkitTextStrokeWidth: "1px",
        color: "rgba(0, 0, 0, 0.2)",
        lineHeight: "1",
    },
    cssLabel: {
        color: "white"
    },
    menuPaper: {
        maxHeight: "108px",
    },
    cssFocused: {}
}));

export const CardForm: React.VFC<{ loading, setLoading}> = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
    const history = useHistory();
    let setOpenLoader = props.setLoading
    const [submit, setSubmit] = useState(true);

    const [textColour, setTextColour] = useState("#293c7e");
    const [secondaryTextColour, setSecondaryTextColour] = useState("#06693e");
    const [tertiaryTextColour, setTertiaryTextColour] = useState("#4a5579");

    useEffect(() => {
        const url = new URL(window.location.href);
        const eventID = url.pathname.split("/")[1]

        const allEventData = JSON.parse(localStorage.getItem("eventData"));
        const eventData = allEventData[eventID];

        if (eventData !== undefined) {
            setTextColour(eventData["web-text-primary-colour"])
            setSecondaryTextColour(eventData["web-text-secondary-colour"])
            setTertiaryTextColour(eventData["web-text-tertiary-colour"])
        }
    }, [location]);

    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);
        setOpenLoader(true);
        function getParentPath(p) {
            // Remove trailing slash if it exists
            const normalizedPath = p.endsWith('/') ? p.slice(0, -1) : p;
            // Get the parent path
            return normalizedPath.slice(0, normalizedPath.lastIndexOf('/'));
        }

        const path = getParentPath(window.location.href) + "/payment-status";

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: path,
            },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occurred.");
        }

        setIsLoading(false);
        setOpenLoader(false);
    };

    return (
            <>
                <Grid container direction={"column"} spacing={2}>
                    <Grid item>
                        <BackButton onClick={() => history.push({pathname: "./", search: history.location.search})}/>
                        <MenuHeader withEventName/>
                    </Grid>
                    <Grid item>
                        <PaymentElement id="payment-element" />
                    </Grid>
                    <Grid item>
                        <NormalButton thin onClick={(e: any) => handleSubmit(e)} disabled={isLoading || !stripe || !elements}>Pay Now</NormalButton>
                    </Grid>
                </Grid>
                {/*<form id="payment-form" onSubmit={handleSubmit}>*/}

                {/*    */}
                {/*    <button disabled={isLoading || !stripe || !elements} id="submit">*/}
                {/*  <span id="button-text">*/}
                {/*    {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}*/}
                {/*  </span>*/}
                {/*    </button>*/}
                {/*    /!* Show any error or success messages *!/*/}
                {/*    {message && <div id="payment-message">{message}</div>}*/}
                {/*</form>*/}
            </>
    );

};
