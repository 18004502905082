import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Typography,
  Box,
  makeStyles,
  Divider,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import {MenuHeader} from "../Components/MenuHeader";
import {NormalButton} from "../Components/NormalButton";
import {handleError} from "../../support";

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: 40,
    padding: theme.spacing(1.5),
    backgroundColor: "white",
  },
  paper: {
    background:
      "linear-gradient(45deg, rgba(215, 160, 42, 0.4) 10%, rgba(23, 69, 118, 0.5) 70%)",
    backdropFilter: "blur(10px)",
    display: "flex-wrap",
    flexWrap: "wrap",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(5, 3),
    marginTop: "auto",
    marginBottom: "auto",
    borderRadius: 30,
    height: "fit-content"
  },
  paperMobile: {
    background:
      "linear-gradient(45deg, rgba(215, 160, 42, 0.4) 10%, rgba(23, 69, 118, 0.5) 70%)",
    display: "flex-wrap",
    flexWrap: "wrap",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3, 1),
    margin: theme.spacing(2, 0),
    borderRadius: 30,
  },
  mobileDivider: {
    width: "80%",
    marginTop: "5%",
    marginBottom: "5%",
    backgroundColor: "#47537a",
  },
  samaggiTextOrange: {
    fontSize: "4.5em",
    color: "#d7a02a",
    lineHeight: "1",
    fontFamily: "gotham-bold",
  },
  samaggiTextTransparent: {
    fontSize: "4.5em",
    WebkitTextStrokeColor: "#d7a02a",
    WebkitTextStrokeWidth: "1px",
    color: "rgba(0, 0, 0, 0.2)",
    lineHeight: "1",
  },
  cssLabel: {
    color: "white",
  },
  cssFocused: {},
}));

export const PaymentStatus: React.VFC<{ loading, setLoading }> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  let setOpenLoader = props.setLoading;

  const [data, setData] = useState(undefined);
  const [msg, setMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [textColour, setTextColour] = useState("#293c7e");
  const [secondaryTextColour, setSecondaryTextColour] = useState("#06693e");
  const [tertiaryTextColour, setTertiaryTextColour] = useState("#4a5579");

  useEffect(() => {
    const url = new URL(window.location.href);
    const eventID = url.pathname.split("/")[1]

    const allEventData = JSON.parse(localStorage.getItem("eventData"));
    const eventData = allEventData[eventID];

    if (eventData !== undefined) {
      setTextColour(eventData["web-text-primary-colour"])
      setSecondaryTextColour(eventData["web-text-secondary-colour"])
      setTertiaryTextColour(eventData["web-text-tertiary-colour"])
    }
  }, [location]);

  async function getPaymentStatus() {
    setOpenLoader(true);
    axios.post("https://ywdjj0mi04.execute-api.eu-west-2.amazonaws.com/Prod/check-payment", {
      "sessionID": localStorage.getItem("sessionId")
    }).then((res) => {
      setData({
        "orderId": res.data.data.orderID
      })
      setMsg(res.data.data.paymentSuccess ? "Payment Complete" : "Payment Failed");
      setSuccess(res.data.data.paymentSuccess);
      setOpenLoader(false);
    }).catch((error) => {
      setOpenLoader(false);
      handleError(error, true);
    })
  }

  useEffect(() => {
    getPaymentStatus();
  }, []);

  console.log(data);

  if (data) {
    return (
      <div>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          alignItems="center"
        >
          <MenuHeader withEventName withDate/>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            marginTop="5%"
          >
            <Typography variant="h6" style={{ color: textColour, fontWeight: "700" }}>
              ORDER NO: {data.orderId}
            </Typography>
            {success && <Typography variant="subtitle1" style={{ color: textColour, width: "85%", textAlign: "center" }}>
              You should soon receive your confirmation email. You can check the order status with your order number.
            </Typography>}
            <Divider className={classes.mobileDivider} />
            <Typography
              component="h5"
              variant="h5"
              style={{ color: textColour, margin: "2px", fontWeight: "700" }}
            >
              {msg}
            </Typography>
            {success && <><Typography
              component="h5"
              variant="h5"
              style={{ color: textColour, margin: "2px", fontWeight: "700" }}
            >
              Thank You for Your Purchase
            </Typography>
            <Typography variant="subtitle1" style={{ color: textColour, width: "85%", textAlign: "center" }}>
              Your ticket is being processed, you will receive an email within 24 hours. If not please check your spam box.
            </Typography></>}
            {!success && <>
              <Typography variant="subtitle1" style={{ color: textColour, width: "85%", textAlign: "center" }}>
                Your payment was not processed. Contact us if the issue persists or if payment has been taken from your account. Do not retry if payment has been deducted from your account.
              </Typography></>}
          </Box>
          <NormalButton thin onClick={() => {history.push({pathname: "./", search: history.location.search});}} style={{marginTop: "20px"}}>
            <Typography variant="subtitle2" style={{ color: textColour }}>
              RETURN TO MAIN MENU
            </Typography>
          </NormalButton>
        </Box>
      </div>
    );
  } else {
    return (
      <Backdrop style={{ color: "#inherit", zIndex: 1 }} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
};
