import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { useHistory, useParams } from "react-router-dom";
import {
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";

// import GothamMedium from "./../fonts/Gotham-Medium.woff2";
// import GothamBold from "./../fonts/Gotham-Bold.woff2";
import {MenuHeader} from "./Components/MenuHeader";
import {ButtonContainer, ButtonTextContainer} from "../Styles";
import {LeftView} from "./Components/LeftView";
import {RightView} from "./Components/RightView";
import {NormalButton} from "./Components/NormalButton";
import {SeatMap} from "./Forms/SeatMap";
import {MainPaper} from "./Components/MainPaper";

const useStyles = makeStyles((_) => ({
  root: {
    position: "fixed",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    padding: 0
  },
  buttonText: {
    color: "#293c7e",
    textAlign: "center",
    width: "100% !important"
  },
}));

export type RouteParams = {
  event: string; // Define other route parameters as needed
};

export const MenuView: React.VFC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const largeMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const { event } = useParams<RouteParams>();

  const [soldOut] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tickets, setTickets] = useState([]);
  const [viewSeat, setViewSeat] = useState(false);

  const [blockBackground, setBlockBackground] = useState("");

  const [onSale, setOnSale] = useState(false);

  const [blockTextMain, setBlockTextMain] = useState("");
  const [blockTextSub, setBlockTextSub] =  useState("");
  const [blockTextMinor, setBlockTextMinor] = useState("");

  const [textColour, setTextColour] = useState("#293c7e");
  const [secondaryTextColour, setSecondaryTextColour] = useState("#06693e");

  let params = new URLSearchParams(history.location.search);

  const handleClickTicket = (e) => {
    localStorage.setItem("selectedTicketType", e.currentTarget.id);
    console.log(e.currentTarget.id);

    history.push({
      pathname: "./single-quantity",
      search: new URLSearchParams({
        selectedTicket: e.currentTarget.id,
        referrer: params.get('referrer') == null ? "" : params.get('referrer')
      }).toString()
    })
  };

  async function getTickets(eventID: string) {
    setLoading(true);
    axios({
      url: process.env.REACT_APP_API_URL,
      method: "post",
      data: {
        query: `
        query GetTickets {
          getTickets (eventID: "${eventID}") {
            details {
              price
              availability
              ticketType
              details
              currency
              fee
              name
              fAmount
              groupID
              sort
            }
          }
         }
        `,
      },
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    })
      .then((result) => {
        const ticketDetails = result.data.data.getTickets.details;
        console.log(ticketDetails)
        if (ticketDetails && ticketDetails.length > 0) {
          setTickets(sortAndArrangeTicket(result.data.data.getTickets.details));
          console.log(JSON.stringify(tickets))
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function checkTicketAvailability(timer) {
    const url = new URL(window.location.href);
    const eventID = url.pathname.split("/")[1]
    console.log(`EVENT ID: ${event}`)

    if (eventID !== "") {
      const allEventData = JSON.parse(localStorage.getItem("eventData"));
      const eventData = allEventData[eventID];

      if (eventData !== undefined) {
        if ((new Date(Date.parse(eventData["web-sales-open"])) > new Date() || new Date() > new Date(Date.parse(eventData["web-sales-close"]))) && (params.get('test') !== "test")) {
          if (new Date(Date.parse(eventData["web-sales-open"])) > new Date()) {
            setBlockTextMain(eventData["web-not-open-title"])
            setBlockTextSub(eventData["web-not-open-subtitle"])
            setBlockTextMinor(eventData["web-not-open-minor"])
          } else {
            setBlockTextMain(eventData["web-closed-title"])
            setBlockTextSub(eventData["web-closed-subtitle"])
            setBlockTextMinor(eventData["web-closed-minor"])
          }
          setOnSale(false);
          setLoading(false);
        } else {
          setOnSale(true);
          localStorage.setItem("sessionId", null);

          getTickets(eventID).then(() => {});
          clearInterval(timer);
        }
        setBlockBackground(eventData["web-supp-block-background"])
      }
      setTextColour(eventData["web-text-primary-colour"])
      setSecondaryTextColour(eventData["web-text-secondary-colour"])
    }
  }


  useEffect(() => {
    if (params.get("referrer") !== null) {
      localStorage.setItem("referrer", params.get("referrer"));
      // localStorage.setItem("referrerDate", (new Date()).getTime());
    }

    let tid = setInterval(() => {
      checkTicketAvailability(tid)
    }, 1000)
    checkTicketAvailability(tid)
  }, []);

  function sortAndArrangeTicket(rawTicketData) {
    const sortedResult = rawTicketData.sort((x,y) => {

      const nameX = x.sort;
      const nameY = y.sort;

      //dope sorting
      //return -1 ? (nameX < nameY) : (1 ? (nameX > nameY) : 0)

      return -1 ? (nameX < nameY) : 1
    })

    let ticketGroups = {};
    let ticketData = {};

    sortedResult.forEach((ticket) => {
      ticketData[ticket.ticketType] = ticket
      if (!ticketGroups[ticket.groupID]) {
        ticketGroups[ticket.groupID] = {
          min: ticket.price,
          max: ticket.price,
          minFee: ticket.fee,
          maxFee: ticket.fee,
          fAmount: ticket.fAmount,
          details: ticket.details,
          currency: ticket.currency,
          groupID: ticket.groupID,
          tickets: [],
          sort: ticket.sort
        }
      }

      if (ticket.price < ticketGroups[ticket.groupID]["min"]) {
        ticketGroups[ticket.groupID]["min"] = ticket.price
      }
      if (ticket.price > ticketGroups[ticket.groupID]["max"]) {
        ticketGroups[ticket.groupID]["max"] = ticket.price
      }
      if (ticket.fee < ticketGroups[ticket.groupID]["minFee"]) {
        ticketGroups[ticket.groupID]["minFee"] = ticket.fee
      }
      if (ticket.fee > ticketGroups[ticket.groupID]["maxFee"]) {
        ticketGroups[ticket.groupID]["maxFee"] = ticket.fee
      }
      if (ticket.sort < ticketGroups[ticket.groupID]["sort"]) {
        ticketGroups[ticket.groupID]["sort"] = ticket.sort
      }

      ticketGroups[ticket.groupID]["tickets"].push(ticket);


      console.log(ticket)
    })

    console.log(ticketGroups)

    localStorage.setItem("groupData", JSON.stringify(ticketGroups));
    localStorage.setItem("ticketData", JSON.stringify(ticketData))

    let newArray = [];

    sortedResult.forEach((ticket) => {
      newArray.push(ticket)
    })

    return Object.values(ticketGroups);
  }

  if (onSale) {
    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <LeftView openLoader={loading}>
          <SeatMap setViewSeat={setViewSeat} viewSeat={viewSeat}/>
          <MainPaper isMobile={isMobile} largeMobile={largeMobile}>
            <MenuHeader withEventName withDate/>
            <Box display="flex" alignItems="center" flexDirection="column" marginTop="5%" width="100%">
              <ButtonContainer>
                {tickets.sort((a, b) => a.sort - b.sort).map((ticket) => (
                <NormalButton id={ticket.groupID} onClick={handleClickTicket} key={ticket.groupID}>
                  <ButtonTextContainer flexDirection="column" px="5%">
                    <Typography variant="subtitle2" className={classes.buttonText} style={{ fontWeight: "bold", color: textColour }}>
                      {ticket.details.toUpperCase()}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.buttonText} style={{ color: textColour }}>
                      {ticket.currency.replaceAll("GBP", "£")}{ticket.min * (ticket.fAmount == 0 ? 1 : ticket.fAmount)} {ticket.min != ticket.max && `- ${ticket.currency.replaceAll("GBP", "£")}${ticket.max * (ticket.fAmount == 0 ? 1 : ticket.fAmount)}`} {ticket.fAmount != 0 && (` / ${ticket.fAmount} tickets`)} {ticket.minFee != 0 && `(+ ${ticket.currency}${ticket.minFee} fees)`}
                    </Typography>
                  </ButtonTextContainer>
                </NormalButton>
              ))}
              </ButtonContainer>

              {soldOut && (
                <NormalButton disabled>
                  <Box flexDirection="column" px="5%">
                    <Typography variant="subtitle2" style={{ color: textColour }}>
                      No Ticket Available
                    </Typography>
                  </Box>
                </NormalButton>
              )}

              {/*<NormalButton thin onClick={() => setViewSeat(true)}>*/}
              {/*  <ButtonTextContainer flexDirection="column" px="5%">*/}
              {/*    <Typography className={classes.buttonText} variant="subtitle2" style={{ color: "#d7a02a", textAlign: "center" }}>*/}
              {/*      View Floor Plan*/}
              {/*    </Typography>*/}
              {/*  </ButtonTextContainer>*/}
              {/*</NormalButton>*/}

              <NormalButton thin onClick={() => history.push({pathname: "./check-status"})}>
                <ButtonTextContainer flexDirection="column" px="5%">
                  <Typography variant="subtitle2" className={classes.buttonText} style={{ color: secondaryTextColour, textAlign: "center", fontWeight: "bold" }}>
                    Check order status
                  </Typography>
                </ButtonTextContainer>
              </NormalButton>

            </Box>
          </MainPaper>
        </LeftView>
        <RightView/>
      </Grid>
    );
  } else if (!onSale) {
    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <LeftView>
          <MainPaper isMobile={isMobile}>
            <MenuHeader withEventName withDate/>
            <Box width="100%" height="100%" display="flex" flexDirection="column" justifyItems="space-between" borderRadius={5} style={{backgroundColor: blockBackground, marginTop: "20px", padding: "10px"}}>
              <Typography variant="subtitle1" style={{ color: "white", textAlign: "center", height: "fit-content", marginTop: "auto", marginBottom: "auto", width: "full", fontWeight: "bold", marginLeft: "20px", marginRight: "20px"}}>
                {blockTextSub}
              </Typography>
              <Typography variant="h6" style={{ color: "white", textAlign: "center", height: "fit-content", marginTop: "auto", marginBottom: "auto", width: "full", fontWeight: "bold", marginLeft: "20px", marginRight: "20px"}}>
                {blockTextMain}
              </Typography>
              <Typography variant="subtitle2" style={{ color: "white", textAlign: "center", height: "fit-content", marginTop: "auto", marginBottom: "auto", width: "full", marginLeft: "20px", marginRight: "20px"}}>
                {blockTextMinor}
              </Typography>
            </Box>
          </MainPaper>
        </LeftView>
        <RightView/>
      </Grid>
    );
  }
};
