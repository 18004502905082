import {Field} from "formik";
import {TextField} from "formik-mui";
import React from "react";

export function SamaggiField(props: { name: string, label: string, placeholder: string, type: string, autoComplete?: string, required?: boolean}) {
    return <Field
        required={props.required === undefined ? true : props.required}
        name={props.name}
        label={props.label}
        placeholder={props.placeholder}
        component={TextField}
        type={props.type}
        style={{width: "100%"}}
        autoComplete={props.autoComplete}
        x-autocompletetype={props.autoComplete}
        InputLabelProps={{
            classes: {
                root: {
                    color: "#293c7e"
                },
                focused: {
                    color: "#293c7e",
                    border: "0px solid lightgray",
                    input: {
                        color: "#293c7e",
                    }
                },
            },
            style: {
                color: "#474d6e"
            }
        }}
        InputProps={{
            classes: {
                root: {
                    color: "#293c7e"
                },
            },
            style: {
                color: "#293c7e"
            }
        }}
    />;
}