import React, {PropsWithChildren, useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import axios from "axios";
import {handleError} from "./support";
import {loadStripe} from "@stripe/stripe-js";

export const Main: React.VFC<PropsWithChildren<any>> = (props) => {

    let location = useLocation()
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const url = new URL(window.location.href);
        const eventID = url.pathname.split("/")[1]

        if (url.pathname.split("/").length === 2 && eventID !== "") {
            window.location.href = `/${eventID}/`
            return
        }

        axios.post("https://ywdjj0mi04.execute-api.eu-west-2.amazonaws.com/Prod/event-data", {
            "eventID": eventID !== undefined ? eventID : ""
        }).then((res) => {
            if (res.data.data.redirect === true) {
                console.log(res.data.data.redirect)
                window.location.href = `/${res.data.data.redirectEvent}/`
                return
            }

            let rawCurrent = localStorage.getItem("eventData");

            if (rawCurrent === null) {
                rawCurrent = "{}";
            }

            const currentData = JSON.parse(rawCurrent);


            currentData[eventID] = res.data.data.data;
            document.title = res.data.data.data["web-page-title"];

            localStorage.setItem("eventData", JSON.stringify(currentData))
            setLoaded(true);
        }).catch((error) => {
            handleError(error, true);
        })
    }, [location]);

    return (
        <>
            {loaded && <>{props.children}</>}
        </>
    )
}